/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #e5eae5;
}

:root {
  --primary-text-color: #6d837a;
  --primary-bg-color: #e5eae5;
}

.custom-data-table {
  overflow: auto;
  width: 100%;
  border-radius: 5px;
  mat-table {
    mat-header-cell {
      background-color: #42cd9b;
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.1;
      letter-spacing: -0.32px;
      justify-content: center;
      padding: 2px 4px !important;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      .mat-sort-header-arrow {
        color: #fff;
      }

      button:focus {
        outline: none;
      }
    }

    mat-row:nth-child(odd) {
      background-color: #fff;
      color: #7c7c7c;
    }
    mat-row:nth-child(even) {
      background-color: #f5f5f5;
      color: #7c7c7c;
    }

    mat-row {
      border-bottom-width: 0 !important;

      &:hover {
        background: #e9edf2;
      }
    }

    mat-cell {
      align-self: stretch;
      padding: 2px 4px !important;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 1px;
      word-break: break-word;
      color: #707070;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}

:root .swal2-height-auto {
  height: 100% !important;
}

:root .cursor-pointer {
  cursor: pointer;
}

:root .block {
  display: block;
}

:root .inline-block {
  display: inline-block;
}

:root .inline {
  display: inline;
}

:root .flex {
  display: flex;
}

:root .table {
  display: table;
}

:root .grid {
  display: grid;
}

:root .hidden {
  display: none;
}

:root .flex-row {
  flex-direction: row;
}

:root .flex-col {
  flex-direction: column;
}

:root .items-start {
  align-items: flex-start;
}

:root .items-end {
  align-items: flex-end;
}

:root .items-center {
  align-items: center;
}

:root .self-end {
  align-self: flex-end;
}

:root .justify-end {
  justify-content: flex-end;
}

:root .justify-center {
  justify-content: center;
}

:root .justify-between {
  justify-content: space-between;
}

:root .flex-1 {
  flex: 1 1 0%;
}

:root .flex-auto {
  flex: 1 1 auto;
}

:root .flex-none {
  flex: none;
}

:root .font-normal {
  font-weight: 400;
}

:root .font-medium {
  font-weight: 500;
}

:root .font-semibold {
  font-weight: 600;
}

:root .font-bold {
  font-weight: 700;
}

:root .h-0 {
  height: 0;
}

:root .h-1 {
  height: 0.25rem;
}

:root .h-3 {
  height: 0.75rem;
}

:root .h-5 {
  height: 1.25rem;
}

:root .h-6 {
  height: 1.5rem;
}

:root .h-8 {
  height: 2rem;
}

:root .h-9 {
  height: 2.25rem;
}

:root .h-10 {
  height: 2.5rem;
}

:root .h-12 {
  height: 3rem;
}

:root .h-14 {
  height: 3.5rem;
}

:root .h-16 {
  height: 4rem;
}

:root .h-24 {
  height: 6rem;
}

:root .h-40 {
  height: 10rem;
}

:root .h-64 {
  height: 16rem;
}

:root .h-full {
  height: 100%;
}

:root .text-xxs {
  font-size: 0.625rem;
}

:root .text-xs {
  font-size: 0.75rem;
}

:root .text-sm {
  font-size: 0.875rem;
}

:root .text-base {
  font-size: 1rem;
}

:root .text-lg {
  font-size: 1.125rem;
}

:root .text-xl {
  font-size: 1.25rem;
}

:root .text-2xl {
  font-size: 1.5rem;
}

:root .w-50 {
  width: 50%;
}

:root .w-100 {
  width: 100%;
}

:root .m-0 {
  margin: 0 !important;
}

:root .m-1 {
  margin: 0.25rem;
}

:root .m-2 {
  margin: 0.5rem;
}

:root .m-3 {
  margin: 0.75rem;
}

:root .m-4 {
  margin: 1rem;
}

:root .m-8 {
  margin: 2rem;
}

:root .m-12 {
  margin: 3rem;
}

:root .my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

:root .mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

:root .my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

:root .mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

:root .my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

:root .mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

:root .my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

:root .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

:root .my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

:root .mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

:root .my-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

:root .mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

:root .my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

:root .my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

:root .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

:root .my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

:root .mt-auto {
  margin-top: auto;
}

:root .mb-auto {
  margin-bottom: auto;
}

:root .-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

:root .-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

:root .-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

:root .mt-0 {
  margin-top: 0;
}

:root .mr-0 {
  margin-right: 0;
}

:root .mb-0 {
  margin-bottom: 0;
}

:root .mt-1 {
  margin-top: 0.25rem;
}

:root .mr-1 {
  margin-right: 0.25rem;
}

:root .mb-1 {
  margin-bottom: 0.25rem;
}

:root .ml-1 {
  margin-left: 0.25rem;
}

:root .mt-2 {
  margin-top: 0.5rem;
}

:root .mr-2 {
  margin-right: 0.5rem;
}

:root .mb-2 {
  margin-bottom: 0.5rem;
}

:root .ml-2 {
  margin-left: 0.5rem;
}

:root .mt-3 {
  margin-top: 0.75rem;
}

:root .mr-3 {
  margin-right: 0.75rem;
}

:root .mb-3 {
  margin-bottom: 0.75rem;
}

:root .mk-3 {
  margin-left: 0.75rem;
}

:root .mt-4 {
  margin-top: 1rem;
}

:root .mr-4 {
  margin-right: 1rem;
}

:root .mb-4 {
  margin-bottom: 1rem;
}

:root .ml-4 {
  margin-left: 1rem;
}

:root .mt-5 {
  margin-top: 1.25rem;
}

:root .mr-5 {
  margin-right: 1.25rem;
}

:root .ml-5 {
  margin-left: 1.25rem;
}

:root .mt-6 {
  margin-top: 1.5rem;
}

:root .mr-6 {
  margin-right: 1.5rem;
}

:root .mb-6 {
  margin-bottom: 1.5rem;
}

:root .mt-8 {
  margin-top: 2rem;
}

:root .mb-8 {
  margin-bottom: 2rem;
}

:root .mt-10 {
  margin-top: 2.5rem;
}

:root .mb-10 {
  margin-bottom: 2.5rem;
}

:root .mt-12 {
  margin-top: 3rem;
}

:root .mb-12 {
  margin-bottom: 3rem;
}

:root .mb-16 {
  margin-bottom: 4rem;
}

:root .mt-16 {
  margin-top: 4rem;
}

:root .mb-17 {
  margin-bottom: 4.5rem;
}

:root .mt-17 {
  margin-top: 4.5rem;
}

:root .mt-19 {
  margin-top: 5rem;
}

:root .mt-21 {
  margin-top: 5.5rem;
}

:root .mt-24 {
  margin-top: 6rem;
}

:root .-ml-1 {
  margin-left: -0.25rem;
}

:root .-mr-2 {
  margin-right: -0.5rem;
}

:root .-mt-6 {
  margin-top: -1.5rem;
}

:root .-mt-14 {
  margin-top: -3.5rem;
}

:root .-mt-16 {
  margin-top: -4rem;
}

:root .-mb-16 {
  margin-bottom: -4rem;
}

:root .max-h-full {
  max-height: 100%;
}

:root .max-w-unset {
  max-width: unset;
}

:root .max-w-xxxs {
  max-width: 16rem;
}

:root .max-w-xxs {
  max-width: 18rem;
}

:root .max-w-xs {
  max-width: 20rem;
}

:root .max-w-sm {
  max-width: 24rem;
}

:root .max-w-md {
  max-width: 28rem;
}

:root .max-w-lg {
  max-width: 32rem;
}

:root .max-w-xl {
  max-width: 36rem;
}

:root .max-w-3xl {
  max-width: 48rem;
}

:root .max-w-full {
  max-width: 100%;
}

:root .min-h-0 {
  min-height: 0;
}

:root .min-w-8 {
  min-width: 2rem;
}

:root .min-w-9 {
  min-width: 2.25rem;
}

:root .object-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

:root .opacity-25 {
  opacity: 0.25;
}

:root .opacity-50 {
  opacity: 0.5;
}

:root .outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

:root .overflow-auto {
  overflow: auto;
}

:root .overflow-hidden {
  overflow: hidden;
}

:root .overflow-x-auto {
  overflow-x: auto;
}

:root .overflow-y-auto {
  overflow-y: auto;
}

:root .overflow-y-auto::-webkit-scrollbar {
  width: 12px;
}

:root .overflow-y-auto::-webkit-scrollbar-track {
  background: #ffffff;
  border: 5px solid #bababa;
  border-radius: 50px;
}

:root .overflow-y-auto::-webkit-scrollbar-track-piece {
  border-radius: 50px;
  background-color: #ffffff;
  border: 1px solid #bababa;
}

:root .overflow-y-auto::-webkit-scrollbar-thumb {
  background: #bababa;
  border: 1px solid #bababa;
  border-radius: 50px;
}

:root .p-0 {
  padding: 0;
}

:root .p-1 {
  padding: 0.25rem;
}

:root .p-2 {
  padding: 0.5rem;
}

:root .p-3 {
  padding: 0.75rem;
}

:root .p-4 {
  padding: 1rem;
}

:root .p-6 {
  padding: 1.5rem;
}

:root .p-12 {
  padding: 3rem;
}

:root .p-16 {
  padding: 4rem;
}

:root .px-0 {
  padding-left: 0;
  padding-right: 0;
}

:root .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

:root .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

:root .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

:root .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

:root .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

:root .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

:root .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

:root .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

:root .px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

:root .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

:root .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

:root .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

:root .py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

:root .pt-0 {
  padding-top: 0;
}

:root .pr-0 {
  padding-right: 0;
}

:root .pb-0 {
  padding-bottom: 0;
}

:root .pt-1 {
  padding-top: 0.25rem;
}

:root .pr-1 {
  padding-right: 0.25rem;
}

:root .pl-1 {
  padding-left: 0.25rem;
}

:root .pb-1 {
  padding-bottom: 0.25rem;
}

:root .pt-2 {
  padding-top: 0.5rem;
}

:root .pr-2 {
  padding-right: 0.5rem;
}

:root .pb-2 {
  padding-bottom: 0.5rem;
}

:root .pt-3 {
  padding-top: 0.75rem;
}

:root .pr-3 {
  padding-right: 0.75rem;
}

:root .pl-3 {
  padding-left: 0.75rem;
}

:root .pt-4 {
  padding-top: 1rem;
}

:root .pr-4 {
  padding-right: 1rem;
}

:root .pb-4 {
  padding-bottom: 1rem;
}

:root .pl-4 {
  padding-left: 1rem;
}

:root .pt-6 {
  padding-top: 1.5rem;
}

:root .pr-6 {
  padding-right: 1.5rem;
}

:root .pb-6 {
  padding-bottom: 1.5rem;
}

:root .pl-6 {
  padding-left: 1.5rem;
}

:root .pr-8 {
  padding-right: 2rem;
}

:root .pt-8 {
  padding-top: 2rem;
}

:root .pb-8 {
  padding-bottom: 2rem;
}

:root .pl-10 {
  padding-left: 2.5rem;
}

:root .pr-10 {
  padding-right: 2.5rem;
}

:root .pt-10 {
  padding-top: 2.5rem;
}

:root .pb-10 {
  padding-bottom: 2.5rem;
}

:root .pt-12 {
  padding-top: 3rem;
}

:root .pb-12 {
  padding-bottom: 3rem;
}
:root .pl-12 {
  padding-left: 3rem;
}

:root .pb-16 {
  padding-bottom: 4rem;
}

:root .pb-20 {
  padding-bottom: 5rem;
}

:root .pt-20 {
  padding-top: 5rem;
}

:root .pb-24 {
  padding-bottom: 6rem;
}

:root .text-left {
  text-align: left;
}

:root .text-center {
  text-align: center;
}

:root .text-right {
  text-align: right;
}

:root .user-select-none {
  user-select: none;
}

:root .outline-none {
  outline: none;
}

:root .btn {
  font-size: 16px;
  font-weight: normal;
  line-height: 48px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

  .mat-button-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
  }
}

:root .btn-primary {
  border: 1px solid #416958;
  background-color: #416958;
  color: #fff;

  &:disabled {
    background-color: #ededed;
    border: 1px solid #ededed;
    color: #d3d4d3;
  }
}

:root .btn-primary-2 {
  border: 1px solid #798d85;
  background-color: #f4f7f4;
  color: #fff;

  &:disabled {
    background-color: #ededed;
    border: 1px solid #ededed;
    color: #d3d4d3;
  }
}

:root .btn-outline-primary {
  border: 1px solid #416958;
  background-color: #fff;
  color: #416958;
}

:root .btn-danger {
  border: 1px solid #d05858;
  background-color: #d05858;
  color: #fff;

  &:disabled {
    border: solid 1px #d3d4d3;
    background-color: #fff;
    color: #d3d4d3;
  }
}

:root .btn-outline-danger {
  border: 1px solid #d05858;
  background-color: #fff;
  color: #d05858;

  &:disabled {
    border: solid 1px #d3d4d3;
    background-color: #fff;
    color: #d3d4d3;
  }
}

.custom-dialog-container {
  .mat-dialog-container {
    padding: 10px 5px 15px;
    border-radius: 5px;
  }

  .mat-dialog-content {
    padding: 0px;
    margin: 0px;
  }
}

.custon-backdrop {
  opacity: 0.64 !important;
  background: #416958;
}

.cdk-overlay-pane.modal-panel-detail, 
.cdk-overlay-pane.modal-panel-cancel,
.cdk-overlay-pane.modal-qr {
  position: absolute !important;

  .mat-dialog-container {
    padding: 0;

    .mat-dialog-content {
      padding: 0px;
      margin: 0px;
      max-height: 95vh;
    }
  }

  .modal-close-button {
    position: absolute;
    width: 28px;
    height: 28px;
    right: 0;
    top: 0;
    background-color: #373637;
    z-index: 9;
    transform: translate(30%, -50%);
    padding: 0 !important;
    color: #fff;

    mat-icon {
      font-size: 1rem;
      line-height: 12px;
    }
  }
}

.cdk-overlay-pane.modal-message,
.cdk-overlay-pane.modal-message-confirmation {
  width: 50%;

  @media only screen and (max-width: 767.98px) {
    min-width: none;
    width: 75%;
  }

  @media only screen and (max-width: 575.98px) {
    min-width: none;
    width: 95%;
  }

  .mat-dialog-container {
    padding: 0px;
    .mat-dialog-content {
      padding: 0px;
      margin: 0px;
      max-height: 90vh;
    }
  }
}

.cdk-overlay-pane.modal-webcam {
  width: 35%;

  @media only screen and (max-width: 767.98px) {
    min-width: none;
    width: 60%;
  }

  @media only screen and (max-width: 575.98px) {
    min-width: none;
    width: 95%;
  }

  .mat-dialog-container {
    padding: 0px;
    .mat-dialog-content {
      padding: 0px;
      margin: 0px;
      max-height: 90vh;
    }
  }
}

.cdk-overlay-pane.modal-digital-signature {
  width: 95%;

  .mat-dialog-container {
    padding: 0px;
    .mat-dialog-content {
      padding: 0px;
      margin: 0px;
      max-height: 90vh;
    }
  }
}

.cdk-overlay-pane.modal-panel-detail,
.cdk-overlay-pane.modal-qr {
  width: 50%;

  @media only screen and (max-width: 767.98px) {
    min-width: none;
    width: 70%;
  }

  @media only screen and (max-width: 575.98px) {
    min-width: none;
    width: 95%;
  }

  .mat-dialog-container {
    padding: 0px;
    .mat-dialog-content {
      padding: 0px;
      margin: 0px;
      max-height: 90vh;
    }
  }
}

.cdk-overlay-pane.modal-panel-cancel {
  width: 50%;

  @media only screen and (max-width: 767.98px) {
    min-width: none;
    width: 75%;
  }

  @media only screen and (max-width: 575.98px) {
    min-width: none;
    width: 95%;
  }
}

.swal2-container .swal2-popup {
  .swal2-icon.swal2-info {
    border-color: #6d837a;
    color: #6d837a;
  }

  .swal2-title {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: 0.16px;
    text-align: center;
    color: #7a797a;
    margin-bottom: 0px;
  }
  
  .swal2-html-container {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.56;
    letter-spacing: 0.16px;
    text-align: center;
    color: #7a797a;
    margin-bottom: 0px;
  }

  .swal2-actions {
    button {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.56;
      letter-spacing: 0.16px;
      min-width: 75px;
      box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.mat-placeholder-required {
  display: none;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #26553C;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #26553C;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #26553C !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336 !important;
}

.mat-input-element {
  caret-color: #26553C !important;
}

.mat-form-field-invalid .mat-input-element, .mat-warn .mat-input-element {
  caret-color: #f44336 !important;
}

.mat-form-field-ripple {
  background-color: #26553C !important;
  color: #26553C !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  background-color: #f44336 !important;
  color: #f44336 !important;
}

.mat-datepicker-toggle-active {
  color: #26553C !important;
}

.mat-calendar-body-active .mat-calendar-body-today {
  background-color: rgba(38,85,60,.2) !important;
}

.mat-calendar-body-selected {
  background-color: #26553C !important;
}

.mat-calendar-body-in-range::before {
  background: rgba(38,85,60,.2);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-indentical) {
  background-color: rgba(38,85,60,.3) !important;
}

.mat-expansion-indicator::after, .mat-expansion-panel-header-description { 
  color: #6d837a;
}

.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
	background-color: #6d837a;
}

.instore-toastr {
  width: nb-theme(swal-toastr-width) !important;
  padding: 0.625em 0 0;

  &.swal2-top-right {
    top: 25px !important;
    right: 15px !important;
    
    @media only screen and (max-width: 575.98px) {
      right: 0px !important;
    }
  }

  .swal2-toast, .swal2-popup {
    width: nb-theme(swal-toastr-width) !important;
    min-height: nb-theme(swal-toastr-heiht) !important;
    background-color: #e8f4fd !important;
    border-radius: 5px;

    button.swal2-close {
      position: absolute;
      top: -3px;
      right: -4px;
      width: 13.5px;
      height: 14.4px;

      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='none'%3E%3Cpath stroke='%23A4ACB6' stroke-width='2' d='M1.764 1.267 17.801 18.45M1.628 18.557 17.555 1.273'/%3E%3C/svg%3E");
      }
    }

    .no-border {
      border: 0 !important;
    }

    .message_container {
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: #286ea9;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        font-size: 2rem;

        &.loading {
          -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite reverse;
          animation: swal2-rotate-loading 1.5s linear 0s infinite reverse;
        }
      }

      .message {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #0d3c61;

        .message_title {
          font-weight: 500;
        }
      }
    }

    .swal2-timer-progress-bar {
      background: #286ea9;
    }
  }
}

.instore-toastr-error {
  width: nb-theme(swal-toastr-width) !important;
  padding: 0.625em 0 0;

  &.swal2-top-right {
    top: 25px !important;
    top: 50px !important;
    right: 15px !important;
    right: 50px !important;
    
    @media only screen and (max-width: 575.98px) {
      right: 0px !important;
    }
  }

  .swal2-toast, .swal2-popup {
    width: nb-theme(swal-toastr-width) !important;
    min-height: nb-theme(swal-toastr-heiht) !important;
    background-color: #fff1f0 !important;
    border-radius: 5px;

    button.swal2-close {
      position: absolute;
      top: -3px;
      right: -4px;
      width: 13.5px;
      height: 14.4px;

      &:before {
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='20' fill='none'%3E%3Cpath stroke='%23A4ACB6' stroke-width='2' d='M1.764 1.267 17.801 18.45M1.628 18.557 17.555 1.273'/%3E%3C/svg%3E");
      }
    }

    .no-border {
      border: 0 !important;
    }

    .message_container_error {
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .icon {
        color: #af525c;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        font-size: 2rem;

        &.loading {
          -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite reverse;
          animation: swal2-rotate-loading 1.5s linear 0s infinite reverse;
        }
      }

      .message {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #af525c;

        .message_title {
          font-weight: 500;
        }
      }
    }

    .swal2-timer-progress-bar {
      background: #af525c;
    }
  }
}
// no deberia ir aqui owl-carousel-custom

.owl-dots {
  margin-top: 2.375rem !important;
}

.owl-dot {
  margin: 0 0.25rem 0 0.25rem;

  span {
    width: 3rem !important;
    height: 0.25rem !important;
    background: #D9D9D9 !important;
    border-radius: 0.625rem !important;
    margin: 0 !important;
  }
}

.owl-dot.active {
  margin: 0 0.25rem 0 0.25rem;
  
  span {
    width: 3rem !important;
    height: 0.25rem !important;
    background: #FB3449 !important;
    border-radius: 0.625rem !important;
    margin: 0 !important;
  }
}
